@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Fira Code', monospace;
}

body.light {
  background-color: #fff;
  color: #333;
}

body.dark {
  background-color: #101820;
  color: #fff;
}

.theme-switcher {
  font-size: 18px;
  cursor: pointer;
  background: none;
  border: none;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #111827;
}

::-webkit-scrollbar-thumb {
  background: #1F2937;
}

::-webkit-scrollbar-thumb:hover {
  background: #151c27;
}

.text-stroke{
  -webkit-text-stroke: 0.2px #101820;
}